import Vue from "vue";

export const module = {
  compare,
  onlyUnique,
  handleResponse,
  handleResp,
  logout,
  notify,
  notifyError,
  notifySuccess,
  numberWithCommas,
  subStringByLimit
};

function compare(a, b) {
  if (a.checked > b.checked) {
    return -1;
  }
  if (a.checked < b.checked) {
    return 1;
  }
  return 0;
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function handleResp(response) {
  const jsonData = response.data;
  if (jsonData.status == 0) {
    if (jsonData.errorCode === 401) {
      logout();
      location.reload(true);
    }
  }
  return jsonData;
}

function handleResponse(response) {
  const jsonData = response.data;
  if (jsonData.status == 0) {
    if (jsonData.errorCode === 401) {
      logout();
      location.reload(true);
    }
    const error = jsonData.message || jsonData.errorCode;

    return Promise.reject(error);
  }
  if (jsonData.data || jsonData.data === 0) {
    return jsonData.data;
  } else {
    return jsonData.status;
  }
}

function logout() {
  localStorage.removeItem("token");
}
function notify(Vue, label, noti, type) {
  Vue.notify({
    group: "notification",
    type: type,
    title: label,
    text: noti
  });
}

function notifyError(noti) {
  Vue.notify({
    group: "notification",
    type: "error",
    text: noti
  });
}

function notifySuccess(label, noti) {
  Vue.notify({
    group: "notification",
    type: "success",
    title: label,
    text: noti
  });
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function subStringByLimit(item, limit) {
  return item.length > limit ? item.substring(0, limit) + "..." : item;
}
