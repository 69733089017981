<template>
  <div>
    <div class="alert alert-light alert-elevate" role="alert">
      <div class="alert-icon alert-icon-top">
        <i class="flaticon-warning kt-font-brand"></i>
      </div>
      <div class="alert-text">
        <p>
          The layout builder helps to configure the layout with preferred
          options and preview it in real time. The configured layout options
          will be saved until you change or reset them. To use the layout
          builder choose the layout options and click the
          <code>Preview</code> button to preview the changes.
        </p>
      </div>
    </div>

    <div class="kt-portlet kt-portlet--tabs">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-toolbar">
          <ul
            ref="builder-tab"
            class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-left nav-tabs-line-primary"
            role="tablist"
          >
            <li class="nav-item">
              <a
                class="nav-link active"
                data-tab="0"
                data-toggle="tab"
                href="#"
                role="tab"
                aria-selected="true"
              >
                Front end
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!--begin::Form-->
      <form
        class="kt-form kt-form--label-right"
        @submit.stop.prevent="onSubmit"
      >
        <div class="kt-portlet__body">
          <b-tabs class="kt-hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div class="kt-section kt-margin-t-30">
                <div class="kt-section__body">
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Title top:</label>
                    <div class="col-lg-9 col-xl-4">
                      <input
                        class="form-control"
                        v-model="config.title_top_header"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Call us:</label>
                    <div class="col-lg-9 col-xl-4">
                      <input class="form-control" v-model="config.call_us" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Title 1:</label>
                    <div class="col-lg-9 col-xl-4">
                      <input class="form-control" v-model="config.title1" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Sub title 1:</label>
                    <div class="col-lg-9 col-xl-4">
                      <input class="form-control" v-model="config.sub_title1" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Title 2:</label>
                    <div class="col-lg-9 col-xl-4">
                      <input class="form-control" v-model="config.title2" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Sub title 2:</label>
                    <div class="col-lg-9 col-xl-4">
                      <input class="form-control" v-model="config.sub_title2" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Title 3:</label>
                    <div class="col-lg-9 col-xl-4">
                      <input class="form-control" v-model="config.title3" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Sub title 3:</label>
                    <div class="col-lg-9 col-xl-4">
                      <input class="form-control" v-model="config.sub_title3" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Title 4:</label>
                    <div class="col-lg-9 col-xl-4">
                      <input class="form-control" v-model="config.title4" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Sub title 4:</label>
                    <div class="col-lg-9 col-xl-4">
                      <input class="form-control" v-model="config.sub_title4" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label"
                      >Footer information:</label
                    >
                    <div class="col-lg-9 col-xl-4">
                      <input
                        class="form-control"
                        v-model="config.footer_information"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label"
                      >Store address:</label
                    >
                    <div class="col-lg-9 col-xl-4">
                      <input
                        class="form-control"
                        v-model="config.store_address"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Email us:</label>
                    <div class="col-lg-9 col-xl-4">
                      <input class="form-control" v-model="config.email_us" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Fax:</label>
                    <div class="col-lg-9 col-xl-4">
                      <input class="form-control" v-model="config.fax" />
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>

        <div class="kt-portlet__foot">
          <div class="kt-form__actions">
            <div class="row">
              <div class="col-lg-3"></div>
              <div class="col-lg-9">
                <button
                  type="submit"
                  id="config_submit"
                  class="btn btn-brand mr-2"
                >
                  <i class="la la-save"></i>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <notifications group="notification" position="bottom right" />
      </form>
      <!--end::Form-->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import {
  GET_LIST_CONFIG,
  SAVE_CONFIG
} from "../../store/configfrontend.module";
import { module } from "../../common/untils/module";

export default {
  name: "config",
  data() {
    return {
      tabIndex: 0,
      notification: []
    };
  },
  components: {},
  computed: {
    ...mapState({
      config: state => state.configfrontend.configs,
      errors: state => state.configfrontend.errors,
      checks: state => state.configfrontend.checks
    })
  },
  methods: {
    /**
     * Submit form
     * @param event
     */
    onSubmit() {
      const id = this.config.id;
      const title_top_header = this.config.title_top_header;
      const call_us = this.config.call_us;
      const title1 = this.config.title1;
      const sub_title1 = this.config.sub_title1;
      const title2 = this.config.title2;
      const sub_title2 = this.config.sub_title2;
      const title3 = this.config.title3;
      const sub_title3 = this.config.sub_title3;
      const title4 = this.config.title4;
      const sub_title4 = this.config.sub_title4;
      const footer_information = this.config.footer_information;
      const store_address = this.config.store_address;
      const email_us = this.config.email_us;
      const fax = this.config.fax;

      // send register request
      this.$store
        .dispatch(SAVE_CONFIG, {
          id: id,
          title_top_header: title_top_header,
          call_us: call_us,
          title1: title1,
          sub_title1: sub_title1,
          title2: title2,
          sub_title2: sub_title2,
          title3: title3,
          sub_title3: sub_title3,
          title4: title4,
          sub_title4: sub_title4,
          footer_information: footer_information,
          store_address: store_address,
          email_us: email_us,
          fax: fax
        })
        .then();
      if (this.checks === 1) {
        module.notifySuccess(this.errors);
      } else {
        module.notifyError(this.errors);
      }
    },

    getConfig() {
      this.$store.dispatch(GET_LIST_CONFIG);
    }
  },
  mounted() {
    this.getConfig();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Config" }]);
  }
};
</script>
